<div fxLayoutAlign="center spaced">
  <div class="search-form">
    <form>
      <mat-form-field>
        <mat-icon class="search-icon" matPrefix>search</mat-icon>
        <input
          matInput
          class="search"
          type="search"
          placeholder="Enter city name"
          type="text"
          [formControl]="search"
        />
      </mat-form-field>
      <div class="err" *ngIf="error">
        <mat-error>{{ error }}</mat-error>
      </div>
    </form>
  </div>
</div>
<div fxLayout="row">
  <div fxFlex></div>
  <mat-card fxFlex="600px">
    <div *ngIf="weather">
      <div>
        <div fxFlex="100%" class="no-margin">
          <mat-card-title class="title">
            {{ weather.city }}, {{ weather.country }}
          </mat-card-title>
          <mat-card-subtitle>
            <span>
              {{ weather.date | date: 'EEEE' }},
              {{ weather.date | date: 'shortTime' }},
            </span>
            <span>
              {{ weather.description | titlecase }}
            </span>
          </mat-card-subtitle>
          <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="center center">
              <div fxFlex="left">
                <span class="large temp"
                  >{{ weather.temperature | roundTemperature }}&deg;C</span
                >
              </div>
              <div fxFlex class="right">
                <span class="{{ icon }} huge my-wi"></span>
              </div>
            </div>
            <mat-card-subtitle>
              <span class="wi wi-strong-wind left sti"></span>
              {{ weather.wind_speed }} km/h Winds
              <span class="spaced"></span>
              <span class="wi wi-humidity sti"></span> {{ weather.humidity }}%
              Humidity
            </mat-card-subtitle>
            <p class="recommendation">{{ recommendation }}</p>
            <mat-divider inset="true"></mat-divider>
            <mat-list>
              <mat-list-item
                role="listitem"
                class="forecast"
                *ngFor="let f of forecast; index as i"
              >
                <span fxFlex class="left">{{ f.dt_txt | date: 'EEEE' }}</span>
                <span fxFlex class="right">
                  <span
                    class="wi wi-{{ icons[f.icon_id].icon }}"
                  ></span>
                </span>
                <span fxFlex class="right">
                  <span>{{ f.max | roundTemperature }}&deg;</span>
                  <span class="lighter"
                    >/ {{ f.min | roundTemperature }}&deg;</span
                  >
                </span>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </div>
      </div>
    </div>
  </mat-card>
  <div fxFlex></div>
</div>
